.gif-block {
  position: relative;
  width: 100%;
  margin-bottom: 0.625rem;

  .background-image {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .gif-images {
    z-index: 1;
    width: 100%;
    height: 100%;

    .swiper-slide {
      overflow: hidden;
      aspect-ratio: 1/1;

      picture img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: top center;
      }
    }
  }

  h2 {
    color: var(--gif1-title-text);
    font-weight: 300;
    font-size: 3rem;
    line-height: 2.8rem;
    font-family: 'Satisfy Regular', serif;
  }

  p {
    color: var(--gif1-title-text);
    padding: 0;
    margin-bottom: 0;
    padding-bottom: 0.938rem;
    font-size: 1rem;
    font-family: 'Josefin Regular', serif;
    line-height: 0.8rem;
  }

  span.btn{
    width: auto;
    background-color: $color-cotton-candy;
    color: black;
    border-radius: 0.938rem;
  }

  &.splitted {
    height: 100%;

    .gif-images {
      position: absolute;
      top: 0;
      left: 0;

      @media only screen and (max-width: 992px) {
        position: relative;
      }
    }
  }

  &:not(.splitted) {
    margin-top: 3rem;
    //height: 320px;

    @media only screen and (min-width: 992px) {
      .swiper-slide {
        //height: 320px;
         aspect-ratio: 3/1;
      }
    }

    @media only screen and (min-width: 1200px) {
      .swiper-slide {
        //height: 380px;
        aspect-ratio: 3/1;
      }
    }

    @media only screen and (min-width: 1400px) {
      .swiper-slide {
        //height: 440px;
        aspect-ratio: 3/1;
      }
    }
  }

}

.gif-block-2 {
  height: 400px;
  width: 100%;

  @media only screen and (min-width: 440px) {
    height: 450px;
  }

  @media only screen and (min-width: 475px) {
    height: 475px;
  }

  @media only screen and (min-width: 500px) {
    height: 500px;
  }

  @media only screen and (min-width: 525px) {
    height: 525px;
  }

  @media only screen and (min-width: 550px) {
    height: 550px;
  }

  .background-image {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .gif-images {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
  }

  h2 {
    color: var(--gif2-title-text);
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.6rem;
    font-family: 'Bely Display W00 Regular', serif;
  }

  span.btn{
    width: 55%;
    background-color: var(--gif2-btn-bg);
    color: var(--gif2-btn-text);
    border-radius: 0.938rem;
  }
}

.opacity-0 {
  opacity: 0;
  transition: opacity 0s;
}

.fade-out {
  opacity: 0;
  transition: opacity .5s;
}

.fade-in {
  opacity: 1;
  transition: opacity .5s;
}
