// Basics
@import "styles";

//Import van header/footer
@import "components/header";
@import "components/footer";

//Import van components
@import "components/swiper";
@import "components/productBlock";
@import "components/productsSlider";

@import "components/homepage/content-block";
@import "components/homepage/banner-block";
@import "components/homepage/customer-photo";
@import "components/homepage/gif-block";
@import "components/homepage/gif-block-2";
@import "components/homepage/slider-type-1";
@import "components/homepage/slider-type-2";
@import "components/homepage/slider-type-3";
@import "components/homepage/categories-type-3";
@import "components/homepage/splitblock-2";
@import "components/homepage/blockOverlay";

//Import van external components
@import "swiper-bundle.min";
@import "fontawesome.scss";

// Start of homepage general scss
.same-position {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.title-block, .btn-block {
  position: absolute;
  z-index: 3;
  width: 100%;
  bottom: 70px;
  left: 0;
  @media only screen and (max-width: 992px){
    bottom: 40px;
  }

  &.pos-top {
    width: 100%;
    top: 0;
    left: 0;
  }

  &.pos-left {
    width: 60%;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }

  &.pos-left-center{
    width: 60%;
    top: 50%;
    left: 10.625rem;
    transform: translate(0, -50%);
  }

  &.pos-right {
    width: 60%;
    top: 50%;
    right: 0;
    left: auto;
    transform: translate(0, -50%);
  }

  &.pos-center {
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
  }
}

#main_content.home {
  .product-slider {
    @media only screen and (max-width: 992px) {
      padding-left: 0.875rem;
    }
  }
}
