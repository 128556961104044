////Sectie met tweede swiper over Halloween
.slider-type-1 {

  h2 {
    color: var(--slider1-title-text);
    padding-top: 1.2rem;
    padding-bottom: 1rem;
    font-size: 1.375rem;
    font-family: 'Josefin Regular', serif;
  }

  .banner-wrapper {
    position: relative;

    .banner-holder {
      z-index: 5;
      position: absolute;
      padding-bottom: 1.5rem;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .banner {
        height: 150px;
        padding: 1rem;
        background-color: #deb682;
        width: 80%;
        text-align: center;
        overflow: hidden;

        h3 {
          color: var(--slider1-subtitle-text);
          font-size: 1.4rem;
          line-height: 1.5rem;
          font-weight: 900;
          font-family: 'Bely Display W00 Regular', serif;
          margin-bottom: 0.3rem;
        }

        p {
          color: var(--slider1-content-text);
          padding: 0;
          margin-bottom: 0;
          font-size: 0.7rem;
          font-family: 'Josefin Regular', serif;
          line-height: 0.8rem;
        }

        a.btn {
          text-decoration: none;
          color: var(--slider1-btn-text);
          background-color: var(--slider1-btn-bg);
          width: 50%;
          border-radius: 0.938rem;
        }
      }
    }

    .swiper-slide {
      height: 400px;
      text-align: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      overflow: hidden;

      @media only screen and (min-width: 440px) {
        height: 450px;
      }

      @media only screen and (min-width: 475px) {
        height: 475px;
      }

      @media only screen and (min-width: 500px) {
        height: 500px;
      }

      @media only screen and (min-width: 525px) {
        height: 525px;
      }

      @media only screen and (min-width: 550px) {
        height: 550px;
      }

      picture {
        border-radius: 1.563rem;
        overflow: hidden;
      }
    }
  }
}
