//Sectie new collection top advert
.slider-type-2 {
  max-width: 1440px !important;
  margin: 0 auto;
  aspect-ratio: 1440 / 650;

  @media only screen and (min-width: 1440px) {
    width: 1440px !important;
    margin: 0 -120px;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    width: 100vw !important;
    margin: 0 calc(((100vw - 1200px) / 2) * -1);
  }

  @media only screen and (max-width: 992px) {
    aspect-ratio: 390 / 558;
  }

  .swiper {
    height: 100%;

    .swiper-slide {
      height: 100%;
      text-align: center;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      .title-block{
        @media only screen and (max-width: 992px) {
          margin-left: -8.438rem;
        }
        .title{
          .span1{
            color: black;
            font-family: 'Bely Display W00 Regular', serif;
            text-shadow: 1px 1px 2px white;
            font-size: 10rem;
            line-height: 1rem;
            margin-left: -20rem;
            font-style: italic;
            @media only screen and (max-width: 992px) {
              font-size: 4rem;
              margin-left: -7.5rem;
            }
          }
          .span2 {
            color: black;
            font-family: 'Bely Display W00 Regular', serif;
            text-shadow: 1px 1px 2px white;
            font-size: 10rem;
            line-height: 6rem;
            font-style: italic;
            @media only screen and (max-width: 992px) {
              font-size: 4rem;
              line-height: 2rem;
            }
          }

          .b1, .b2 {
            color: #D2070D;
            font-family: $font-family-body;
            text-transform: uppercase;
            text-shadow: none;
            font-style: italic;
            font-weight: 600;
            font-size: 4rem;
            line-height: 3rem;
            @media only screen and (max-width: 992px) {
              font-size: 1.5rem;
            }
          }
          .b1{
            margin-left: 0.625rem;
            @media only screen and (max-width: 992px) {
              margin-left: 0.3rem;
            }
          }
          .b2 {
            margin-left: 25rem;
            @media only screen and (max-width: 992px) {
              margin-left: 6rem;
            }
          }
        }
        .subtitle {
          color: var(--slider2-title-text);
          font-size: 1.5rem;
          line-height: 1.8rem;
          font-family: 'Bely Display W00 Regular', serif;
          margin-bottom: 0;

          @media only screen and (min-width: 992px) {
            font-size: 3rem;
            line-height: 3.3rem;
          }
        }

        .content {
          color: white;
          font-size: 1.2rem;
          font-weight: 400;
          margin-bottom: 0.5rem;

          @media only screen and (min-width: 992px) {
            font-size: 2.5rem;
          }
        }
      }

      .btn-block{
        //bottom: 70px;

        span.btn {
          color: var(--slider2-btn-text);
          background-color: var(--slider2-btn-bg);
          width: auto;
          border-radius: 0.938rem;

          @media only screen and (min-width: 992px) {
            width: 200px;
          }
        }
      }

    }
  }
}
