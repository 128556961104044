.overlay-image {
  position: absolute;
  height: auto !important;
  // top:  left:  bottom:  width:  set by using classes below

  img {
    height: auto !important;
  }
}

// Top Positions
.ot-auto { top: auto; }
.ot-1p  { top: 1%; }
.ot-2p  { top: 2%; }
.ot-3p  { top: 3%; }
.ot-4p  { top: 4%; }
.ot-5p  { top: 5%; }
.ot-6p  { top: 6%; }
.ot-7p  { top: 7%; }
.ot-8p  { top: 8%; }
.ot-9p  { top: 9%; }
.ot-10p { top: 10%; }
.ot-11p { top: 11%; }
.ot-12p { top: 12%; }
.ot-13p { top: 13%; }
.ot-14p { top: 14%; }
.ot-15p { top: 15%; }
.ot-16p { top: 16%; }
.ot-17p { top: 17%; }
.ot-18p { top: 18%; }
.ot-19p { top: 19%; }
.ot-20p { top: 20%; }
.ot-21p { top: 21%; }
.ot-22p { top: 22%; }
.ot-23p { top: 23%; }
.ot-24p { top: 24%; }
.ot-25p { top: 25%; }
.ot-26p { top: 26%; }
.ot-27p { top: 27%; }
.ot-28p { top: 28%; }
.ot-29p { top: 29%; }
.ot-30p { top: 30%; }
.ot-31p { top: 31%; }
.ot-32p { top: 32%; }
.ot-33p { top: 33%; }
.ot-34p { top: 34%; }
.ot-35p { top: 35%; }
.ot-36p { top: 36%; }
.ot-37p { top: 37%; }
.ot-38p { top: 38%; }
.ot-39p { top: 39%; }
.ot-40p { top: 40%; }
.ot-41p { top: 41%; }
.ot-42p { top: 42%; }
.ot-43p { top: 43%; }
.ot-44p { top: 44%; }
.ot-45p { top: 45%; }
.ot-46p { top: 46%; }
.ot-47p { top: 47%; }
.ot-48p { top: 48%; }
.ot-49p { top: 49%; }
.ot-50p { top: 50%; }
.ot-51p { top: 51%; }
.ot-52p { top: 52%; }
.ot-53p { top: 53%; }
.ot-54p { top: 54%; }
.ot-55p { top: 55%; }
.ot-56p { top: 56%; }
.ot-57p { top: 57%; }
.ot-58p { top: 58%; }
.ot-59p { top: 59%; }
.ot-60p { top: 60%; }
.ot-61p { top: 61%; }
.ot-62p { top: 62%; }
.ot-63p { top: 63%; }
.ot-64p { top: 64%; }
.ot-65p { top: 65%; }
.ot-66p { top: 66%; }
.ot-67p { top: 67%; }
.ot-68p { top: 68%; }
.ot-69p { top: 69%; }
.ot-70p { top: 70%; }
.ot-71p { top: 71%; }
.ot-72p { top: 72%; }
.ot-73p { top: 73%; }
.ot-74p { top: 74%; }
.ot-75p { top: 75%; }
.ot-76p { top: 76%; }
.ot-77p { top: 77%; }
.ot-78p { top: 78%; }
.ot-79p { top: 79%; }
.ot-80p { top: 80%; }
.ot-81p { top: 81%; }
.ot-82p { top: 82%; }
.ot-83p { top: 83%; }
.ot-84p { top: 84%; }
.ot-85p { top: 85%; }
.ot-86p { top: 86%; }
.ot-87p { top: 87%; }
.ot-88p { top: 88%; }
.ot-89p { top: 89%; }
.ot-90p { top: 90%; }
.ot-91p { top: 91%; }
.ot-92p { top: 92%; }
.ot-93p { top: 93%; }
.ot-94p { top: 94%; }
.ot-95p { top: 95%; }
.ot-96p { top: 96%; }
.ot-97p { top: 97%; }
.ot-98p { top: 98%; }
.ot-99p { top: 99%; }
.ot-100p { top: 100%; }

// Left positions
.ol-auto { left: auto; }
.ol-1p { left: 1%; }
.ol-2p { left: 2%; }
.ol-3p { left: 3%; }
.ol-4p { left: 4%; }
.ol-5p { left: 5%; }
.ol-6p { left: 6%; }
.ol-7p { left: 7%; }
.ol-8p { left: 8%; }
.ol-9p { left: 9%; }
.ol-10p { left: 10%; }
.ol-11p { left: 11%; }
.ol-12p { left: 12%; }
.ol-13p { left: 13%; }
.ol-14p { left: 14%; }
.ol-15p { left: 15%; }
.ol-16p { left: 16%; }
.ol-17p { left: 17%; }
.ol-18p { left: 18%; }
.ol-19p { left: 19%; }
.ol-20p { left: 20%; }
.ol-21p { left: 21%; }
.ol-22p { left: 22%; }
.ol-23p { left: 23%; }
.ol-24p { left: 24%; }
.ol-25p { left: 25%; }
.ol-26p { left: 26%; }
.ol-27p { left: 27%; }
.ol-28p { left: 28%; }
.ol-29p { left: 29%; }
.ol-30p { left: 30%; }
.ol-31p { left: 31%; }
.ol-32p { left: 32%; }
.ol-33p { left: 33%; }
.ol-34p { left: 34%; }
.ol-35p { left: 35%; }
.ol-36p { left: 36%; }
.ol-37p { left: 37%; }
.ol-38p { left: 38%; }
.ol-39p { left: 39%; }
.ol-40p { left: 40%; }
.ol-41p { left: 41%; }
.ol-42p { left: 42%; }
.ol-43p { left: 43%; }
.ol-44p { left: 44%; }
.ol-45p { left: 45%; }
.ol-46p { left: 46%; }
.ol-47p { left: 47%; }
.ol-48p { left: 48%; }
.ol-49p { left: 49%; }
.ol-50p { left: 50%; }
.ol-51p { left: 51%; }
.ol-52p { left: 52%; }
.ol-53p { left: 53%; }
.ol-54p { left: 54%; }
.ol-55p { left: 55%; }
.ol-56p { left: 56%; }
.ol-57p { left: 57%; }
.ol-58p { left: 58%; }
.ol-59p { left: 59%; }
.ol-60p { left: 60%; }
.ol-61p { left: 61%; }
.ol-62p { left: 62%; }
.ol-63p { left: 63%; }
.ol-64p { left: 64%; }
.ol-65p { left: 65%; }
.ol-66p { left: 66%; }
.ol-67p { left: 67%; }
.ol-68p { left: 68%; }
.ol-69p { left: 69%; }
.ol-70p { left: 70%; }
.ol-71p { left: 71%; }
.ol-72p { left: 72%; }
.ol-73p { left: 73%; }
.ol-74p { left: 74%; }
.ol-75p { left: 75%; }
.ol-76p { left: 76%; }
.ol-77p { left: 77%; }
.ol-78p { left: 78%; }
.ol-79p { left: 79%; }
.ol-80p { left: 80%; }
.ol-81p { left: 81%; }
.ol-82p { left: 82%; }
.ol-83p { left: 83%; }
.ol-84p { left: 84%; }
.ol-85p { left: 85%; }
.ol-86p { left: 86%; }
.ol-87p { left: 87%; }
.ol-88p { left: 88%; }
.ol-89p { left: 89%; }
.ol-90p { left: 90%; }
.ol-91p { left: 91%; }
.ol-92p { left: 92%; }
.ol-93p { left: 93%; }
.ol-94p { left: 94%; }
.ol-95p { left: 95%; }
.ol-96p { left: 96%; }
.ol-97p { left: 97%; }
.ol-98p { left: 98%; }
.ol-99p { left: 99%; }
.ol-100p { left: 100%; }

// Bottom positions
.ob-auto { bottom: auto; }
.ob-1p { bottom: 1%; }
.ob-2p { bottom: 2%; }
.ob-3p { bottom: 3%; }
.ob-4p { bottom: 4%; }
.ob-5p { bottom: 5%; }
.ob-6p { bottom: 6%; }
.ob-7p { bottom: 7%; }
.ob-8p { bottom: 8%; }
.ob-9p { bottom: 9%; }
.ob-10p { bottom: 10%; }
.ob-11p { bottom: 11%; }
.ob-12p { bottom: 12%; }
.ob-13p { bottom: 13%; }
.ob-14p { bottom: 14%; }
.ob-15p { bottom: 15%; }
.ob-16p { bottom: 16%; }
.ob-17p { bottom: 17%; }
.ob-18p { bottom: 18%; }
.ob-19p { bottom: 19%; }
.ob-20p { bottom: 20%; }
.ob-21p { bottom: 21%; }
.ob-22p { bottom: 22%; }
.ob-23p { bottom: 23%; }
.ob-24p { bottom: 24%; }
.ob-25p { bottom: 25%; }
.ob-26p { bottom: 26%; }
.ob-27p { bottom: 27%; }
.ob-28p { bottom: 28%; }
.ob-29p { bottom: 29%; }
.ob-30p { bottom: 30%; }
.ob-31p { bottom: 31%; }
.ob-32p { bottom: 32%; }
.ob-33p { bottom: 33%; }
.ob-34p { bottom: 34%; }
.ob-35p { bottom: 35%; }
.ob-36p { bottom: 36%; }
.ob-37p { bottom: 37%; }
.ob-38p { bottom: 38%; }
.ob-39p { bottom: 39%; }
.ob-40p { bottom: 40%; }
.ob-41p { bottom: 41%; }
.ob-42p { bottom: 42%; }
.ob-43p { bottom: 43%; }
.ob-44p { bottom: 44%; }
.ob-45p { bottom: 45%; }
.ob-46p { bottom: 46%; }
.ob-47p { bottom: 47%; }
.ob-48p { bottom: 48%; }
.ob-49p { bottom: 49%; }
.ob-50p { bottom: 50%; }
.ob-51p { bottom: 51%; }
.ob-52p { bottom: 52%; }
.ob-53p { bottom: 53%; }
.ob-54p { bottom: 54%; }
.ob-55p { bottom: 55%; }
.ob-56p { bottom: 56%; }
.ob-57p { bottom: 57%; }
.ob-58p { bottom: 58%; }
.ob-59p { bottom: 59%; }
.ob-60p { bottom: 60%; }
.ob-61p { bottom: 61%; }
.ob-62p { bottom: 62%; }
.ob-63p { bottom: 63%; }
.ob-64p { bottom: 64%; }
.ob-65p { bottom: 65%; }
.ob-66p { bottom: 66%; }
.ob-67p { bottom: 67%; }
.ob-68p { bottom: 68%; }
.ob-69p { bottom: 69%; }
.ob-70p { bottom: 70%; }
.ob-71p { bottom: 71%; }
.ob-72p { bottom: 72%; }
.ob-73p { bottom: 73%; }
.ob-74p { bottom: 74%; }
.ob-75p { bottom: 75%; }
.ob-76p { bottom: 76%; }
.ob-77p { bottom: 77%; }
.ob-78p { bottom: 78%; }
.ob-79p { bottom: 79%; }
.ob-80p { bottom: 80%; }
.ob-81p { bottom: 81%; }
.ob-82p { bottom: 82%; }
.ob-83p { bottom: 83%; }
.ob-84p { bottom: 84%; }
.ob-85p { bottom: 85%; }
.ob-86p { bottom: 86%; }
.ob-87p { bottom: 87%; }
.ob-88p { bottom: 88%; }
.ob-89p { bottom: 89%; }
.ob-90p { bottom: 90%; }
.ob-91p { bottom: 91%; }
.ob-92p { bottom: 92%; }
.ob-93p { bottom: 93%; }
.ob-94p { bottom: 94%; }
.ob-95p { bottom: 95%; }
.ob-96p { bottom: 96%; }
.ob-97p { bottom: 97%; }
.ob-98p { bottom: 98%; }
.ob-99p { bottom: 99%; }
.ob-100p { bottom: 100%; }

// Width
.ow-auto { width: auto; }
.ow-1p { width: 1%; }
.ow-2p { width: 2%; }
.ow-3p { width: 3%; }
.ow-4p { width: 4%; }
.ow-5p { width: 5%; }
.ow-6p { width: 6%; }
.ow-7p { width: 7%; }
.ow-8p { width: 8%; }
.ow-9p { width: 9%; }
.ow-10p { width: 10%; }
.ow-11p { width: 11%; }
.ow-12p { width: 12%; }
.ow-13p { width: 13%; }
.ow-14p { width: 14%; }
.ow-15p { width: 15%; }
.ow-16p { width: 16%; }
.ow-17p { width: 17%; }
.ow-18p { width: 18%; }
.ow-19p { width: 19%; }
.ow-20p { width: 20%; }
.ow-21p { width: 21%; }
.ow-22p { width: 22%; }
.ow-23p { width: 23%; }
.ow-24p { width: 24%; }
.ow-25p { width: 25%; }
.ow-26p { width: 26%; }
.ow-27p { width: 27%; }
.ow-28p { width: 28%; }
.ow-29p { width: 29%; }
.ow-30p { width: 30%; }
.ow-31p { width: 31%; }
.ow-32p { width: 32%; }
.ow-33p { width: 33%; }
.ow-34p { width: 34%; }
.ow-35p { width: 35%; }
.ow-36p { width: 36%; }
.ow-37p { width: 37%; }
.ow-38p { width: 38%; }
.ow-39p { width: 39%; }
.ow-40p { width: 40%; }
.ow-41p { width: 41%; }
.ow-42p { width: 42%; }
.ow-43p { width: 43%; }
.ow-44p { width: 44%; }
.ow-45p { width: 45%; }
.ow-46p { width: 46%; }
.ow-47p { width: 47%; }
.ow-48p { width: 48%; }
.ow-49p { width: 49%; }
.ow-50p { width: 50%; }
.ow-51p { width: 51%; }
.ow-52p { width: 52%; }
.ow-53p { width: 53%; }
.ow-54p { width: 54%; }
.ow-55p { width: 55%; }
.ow-56p { width: 56%; }
.ow-57p { width: 57%; }
.ow-58p { width: 58%; }
.ow-59p { width: 59%; }
.ow-60p { width: 60%; }
.ow-61p { width: 61%; }
.ow-62p { width: 62%; }
.ow-63p { width: 63%; }
.ow-64p { width: 64%; }
.ow-65p { width: 65%; }
.ow-66p { width: 66%; }
.ow-67p { width: 67%; }
.ow-68p { width: 68%; }
.ow-69p { width: 69%; }
.ow-70p { width: 70%; }
.ow-71p { width: 71%; }
.ow-72p { width: 72%; }
.ow-73p { width: 73%; }
.ow-74p { width: 74%; }
.ow-75p { width: 75%; }
.ow-76p { width: 76%; }
.ow-77p { width: 77%; }
.ow-78p { width: 78%; }
.ow-79p { width: 79%; }
.ow-80p { width: 80%; }
.ow-81p { width: 81%; }
.ow-82p { width: 82%; }
.ow-83p { width: 83%; }
.ow-84p { width: 84%; }
.ow-85p { width: 85%; }
.ow-86p { width: 86%; }
.ow-87p { width: 87%; }
.ow-88p { width: 88%; }
.ow-89p { width: 89%; }
.ow-90p { width: 90%; }
.ow-91p { width: 91%; }
.ow-92p { width: 92%; }
.ow-93p { width: 93%; }
.ow-94p { width: 94%; }
.ow-95p { width: 95%; }
.ow-96p { width: 96%; }
.ow-97p { width: 97%; }
.ow-98p { width: 98%; }
.ow-99p { width: 99%; }
.ow-100p { width: 100%; }

// Big/Desktop screens
@media only screen and (min-width: 992px) {
  .ol-lg-auto { left: auto; }
  .ol-lg-1p { left: 1%; }
  .ol-lg-2p { left: 2%; }
  .ol-lg-3p { left: 3%; }
  .ol-lg-4p { left: 4%; }
  .ol-lg-5p { left: 5%; }
  .ol-lg-6p { left: 6%; }
  .ol-lg-7p { left: 7%; }
  .ol-lg-8p { left: 8%; }
  .ol-lg-9p { left: 9%; }
  .ol-lg-10p { left: 10%; }
  .ol-lg-11p { left: 11%; }
  .ol-lg-12p { left: 12%; }
  .ol-lg-13p { left: 13%; }
  .ol-lg-14p { left: 14%; }
  .ol-lg-15p { left: 15%; }
  .ol-lg-16p { left: 16%; }
  .ol-lg-17p { left: 17%; }
  .ol-lg-18p { left: 18%; }
  .ol-lg-19p { left: 19%; }
  .ol-lg-20p { left: 20%; }
  .ol-lg-21p { left: 21%; }
  .ol-lg-22p { left: 22%; }
  .ol-lg-23p { left: 23%; }
  .ol-lg-24p { left: 24%; }
  .ol-lg-25p { left: 25%; }
  .ol-lg-26p { left: 26%; }
  .ol-lg-27p { left: 27%; }
  .ol-lg-28p { left: 28%; }
  .ol-lg-29p { left: 29%; }
  .ol-lg-30p { left: 30%; }
  .ol-lg-31p { left: 31%; }
  .ol-lg-32p { left: 32%; }
  .ol-lg-33p { left: 33%; }
  .ol-lg-34p { left: 34%; }
  .ol-lg-35p { left: 35%; }
  .ol-lg-36p { left: 36%; }
  .ol-lg-37p { left: 37%; }
  .ol-lg-38p { left: 38%; }
  .ol-lg-39p { left: 39%; }
  .ol-lg-40p { left: 40%; }
  .ol-lg-41p { left: 41%; }
  .ol-lg-42p { left: 42%; }
  .ol-lg-43p { left: 43%; }
  .ol-lg-44p { left: 44%; }
  .ol-lg-45p { left: 45%; }
  .ol-lg-46p { left: 46%; }
  .ol-lg-47p { left: 47%; }
  .ol-lg-48p { left: 48%; }
  .ol-lg-49p { left: 49%; }
  .ol-lg-50p { left: 50%; }
  .ol-lg-51p { left: 51%; }
  .ol-lg-52p { left: 52%; }
  .ol-lg-53p { left: 53%; }
  .ol-lg-54p { left: 54%; }
  .ol-lg-55p { left: 55%; }
  .ol-lg-56p { left: 56%; }
  .ol-lg-57p { left: 57%; }
  .ol-lg-58p { left: 58%; }
  .ol-lg-59p { left: 59%; }
  .ol-lg-60p { left: 60%; }
  .ol-lg-61p { left: 61%; }
  .ol-lg-62p { left: 62%; }
  .ol-lg-63p { left: 63%; }
  .ol-lg-64p { left: 64%; }
  .ol-lg-65p { left: 65%; }
  .ol-lg-66p { left: 66%; }
  .ol-lg-67p { left: 67%; }
  .ol-lg-68p { left: 68%; }
  .ol-lg-69p { left: 69%; }
  .ol-lg-70p { left: 70%; }
  .ol-lg-71p { left: 71%; }
  .ol-lg-72p { left: 72%; }
  .ol-lg-73p { left: 73%; }
  .ol-lg-74p { left: 74%; }
  .ol-lg-75p { left: 75%; }
  .ol-lg-76p { left: 76%; }
  .ol-lg-77p { left: 77%; }
  .ol-lg-78p { left: 78%; }
  .ol-lg-79p { left: 79%; }
  .ol-lg-80p { left: 80%; }
  .ol-lg-81p { left: 81%; }
  .ol-lg-82p { left: 82%; }
  .ol-lg-83p { left: 83%; }
  .ol-lg-84p { left: 84%; }
  .ol-lg-85p { left: 85%; }
  .ol-lg-86p { left: 86%; }
  .ol-lg-87p { left: 87%; }
  .ol-lg-88p { left: 88%; }
  .ol-lg-89p { left: 89%; }
  .ol-lg-90p { left: 90%; }
  .ol-lg-91p { left: 91%; }
  .ol-lg-92p { left: 92%; }
  .ol-lg-93p { left: 93%; }
  .ol-lg-94p { left: 94%; }
  .ol-lg-95p { left: 95%; }
  .ol-lg-96p { left: 96%; }
  .ol-lg-97p { left: 97%; }
  .ol-lg-98p { left: 98%; }
  .ol-lg-99p { left: 99%; }
  .ol-lg-100p { left: 100%; }

  .ot-lg-auto { top: auto; }
  .ot-lg-1p { top: 1%; }
  .ot-lg-2p { top: 2%; }
  .ot-lg-3p { top: 3%; }
  .ot-lg-4p { top: 4%; }
  .ot-lg-5p { top: 5%; }
  .ot-lg-6p { top: 6%; }
  .ot-lg-7p { top: 7%; }
  .ot-lg-8p { top: 8%; }
  .ot-lg-9p { top: 9%; }
  .ot-lg-10p { top: 10%; }
  .ot-lg-11p { top: 11%; }
  .ot-lg-12p { top: 12%; }
  .ot-lg-13p { top: 13%; }
  .ot-lg-14p { top: 14%; }
  .ot-lg-15p { top: 15%; }
  .ot-lg-16p { top: 16%; }
  .ot-lg-17p { top: 17%; }
  .ot-lg-18p { top: 18%; }
  .ot-lg-19p { top: 19%; }
  .ot-lg-20p { top: 20%; }
  .ot-lg-21p { top: 21%; }
  .ot-lg-22p { top: 22%; }
  .ot-lg-23p { top: 23%; }
  .ot-lg-24p { top: 24%; }
  .ot-lg-25p { top: 25%; }
  .ot-lg-26p { top: 26%; }
  .ot-lg-27p { top: 27%; }
  .ot-lg-28p { top: 28%; }
  .ot-lg-29p { top: 29%; }
  .ot-lg-30p { top: 30%; }
  .ot-lg-31p { top: 31%; }
  .ot-lg-32p { top: 32%; }
  .ot-lg-33p { top: 33%; }
  .ot-lg-34p { top: 34%; }
  .ot-lg-35p { top: 35%; }
  .ot-lg-36p { top: 36%; }
  .ot-lg-37p { top: 37%; }
  .ot-lg-38p { top: 38%; }
  .ot-lg-39p { top: 39%; }
  .ot-lg-40p { top: 40%; }
  .ot-lg-41p { top: 41%; }
  .ot-lg-42p { top: 42%; }
  .ot-lg-43p { top: 43%; }
  .ot-lg-44p { top: 44%; }
  .ot-lg-45p { top: 45%; }
  .ot-lg-46p { top: 46%; }
  .ot-lg-47p { top: 47%; }
  .ot-lg-48p { top: 48%; }
  .ot-lg-49p { top: 49%; }
  .ot-lg-50p { top: 50%; }
  .ot-lg-51p { top: 51%; }
  .ot-lg-52p { top: 52%; }
  .ot-lg-53p { top: 53%; }
  .ot-lg-54p { top: 54%; }
  .ot-lg-55p { top: 55%; }
  .ot-lg-56p { top: 56%; }
  .ot-lg-57p { top: 57%; }
  .ot-lg-58p { top: 58%; }
  .ot-lg-59p { top: 59%; }
  .ot-lg-60p { top: 60%; }
  .ot-lg-61p { top: 61%; }
  .ot-lg-62p { top: 62%; }
  .ot-lg-63p { top: 63%; }
  .ot-lg-64p { top: 64%; }
  .ot-lg-65p { top: 65%; }
  .ot-lg-66p { top: 66%; }
  .ot-lg-67p { top: 67%; }
  .ot-lg-68p { top: 68%; }
  .ot-lg-69p { top: 69%; }
  .ot-lg-70p { top: 70%; }
  .ot-lg-71p { top: 71%; }
  .ot-lg-72p { top: 72%; }
  .ot-lg-73p { top: 73%; }
  .ot-lg-74p { top: 74%; }
  .ot-lg-75p { top: 75%; }
  .ot-lg-76p { top: 76%; }
  .ot-lg-77p { top: 77%; }
  .ot-lg-78p { top: 78%; }
  .ot-lg-79p { top: 79%; }
  .ot-lg-80p { top: 80%; }
  .ot-lg-81p { top: 81%; }
  .ot-lg-82p { top: 82%; }
  .ot-lg-83p { top: 83%; }
  .ot-lg-84p { top: 84%; }
  .ot-lg-85p { top: 85%; }
  .ot-lg-86p { top: 86%; }
  .ot-lg-87p { top: 87%; }
  .ot-lg-88p { top: 88%; }
  .ot-lg-89p { top: 89%; }
  .ot-lg-90p { top: 90%; }
  .ot-lg-91p { top: 91%; }
  .ot-lg-92p { top: 92%; }
  .ot-lg-93p { top: 93%; }
  .ot-lg-94p { top: 94%; }
  .ot-lg-95p { top: 95%; }
  .ot-lg-96p { top: 96%; }
  .ot-lg-97p { top: 97%; }
  .ot-lg-98p { top: 98%; }
  .ot-lg-99p { top: 99%; }
  .ot-lg-100p { top: 100%; }

  .ob-lg-auto { bottom: auto; }
  .ob-lg-1p { bottom: 1%; }
  .ob-lg-2p { bottom: 2%; }
  .ob-lg-3p { bottom: 3%; }
  .ob-lg-4p { bottom: 4%; }
  .ob-lg-5p { bottom: 5%; }
  .ob-lg-6p { bottom: 6%; }
  .ob-lg-7p { bottom: 7%; }
  .ob-lg-8p { bottom: 8%; }
  .ob-lg-9p { bottom: 9%; }
  .ob-lg-10p { bottom: 10%; }
  .ob-lg-11p { bottom: 11%; }
  .ob-lg-12p { bottom: 12%; }
  .ob-lg-13p { bottom: 13%; }
  .ob-lg-14p { bottom: 14%; }
  .ob-lg-15p { bottom: 15%; }
  .ob-lg-16p { bottom: 16%; }
  .ob-lg-17p { bottom: 17%; }
  .ob-lg-18p { bottom: 18%; }
  .ob-lg-19p { bottom: 19%; }
  .ob-lg-20p { bottom: 20%; }
  .ob-lg-21p { bottom: 21%; }
  .ob-lg-22p { bottom: 22%; }
  .ob-lg-23p { bottom: 23%; }
  .ob-lg-24p { bottom: 24%; }
  .ob-lg-25p { bottom: 25%; }
  .ob-lg-26p { bottom: 26%; }
  .ob-lg-27p { bottom: 27%; }
  .ob-lg-28p { bottom: 28%; }
  .ob-lg-29p { bottom: 29%; }
  .ob-lg-30p { bottom: 30%; }
  .ob-lg-31p { bottom: 31%; }
  .ob-lg-32p { bottom: 32%; }
  .ob-lg-33p { bottom: 33%; }
  .ob-lg-34p { bottom: 34%; }
  .ob-lg-35p { bottom: 35%; }
  .ob-lg-36p { bottom: 36%; }
  .ob-lg-37p { bottom: 37%; }
  .ob-lg-38p { bottom: 38%; }
  .ob-lg-39p { bottom: 39%; }
  .ob-lg-40p { bottom: 40%; }
  .ob-lg-41p { bottom: 41%; }
  .ob-lg-42p { bottom: 42%; }
  .ob-lg-43p { bottom: 43%; }
  .ob-lg-44p { bottom: 44%; }
  .ob-lg-45p { bottom: 45%; }
  .ob-lg-46p { bottom: 46%; }
  .ob-lg-47p { bottom: 47%; }
  .ob-lg-48p { bottom: 48%; }
  .ob-lg-49p { bottom: 49%; }
  .ob-lg-50p { bottom: 50%; }
  .ob-lg-51p { bottom: 51%; }
  .ob-lg-52p { bottom: 52%; }
  .ob-lg-53p { bottom: 53%; }
  .ob-lg-54p { bottom: 54%; }
  .ob-lg-55p { bottom: 55%; }
  .ob-lg-56p { bottom: 56%; }
  .ob-lg-57p { bottom: 57%; }
  .ob-lg-58p { bottom: 58%; }
  .ob-lg-59p { bottom: 59%; }
  .ob-lg-60p { bottom: 60%; }
  .ob-lg-61p { bottom: 61%; }
  .ob-lg-62p { bottom: 62%; }
  .ob-lg-63p { bottom: 63%; }
  .ob-lg-64p { bottom: 64%; }
  .ob-lg-65p { bottom: 65%; }
  .ob-lg-66p { bottom: 66%; }
  .ob-lg-67p { bottom: 67%; }
  .ob-lg-68p { bottom: 68%; }
  .ob-lg-69p { bottom: 69%; }
  .ob-lg-70p { bottom: 70%; }
  .ob-lg-71p { bottom: 71%; }
  .ob-lg-72p { bottom: 72%; }
  .ob-lg-73p { bottom: 73%; }
  .ob-lg-74p { bottom: 74%; }
  .ob-lg-75p { bottom: 75%; }
  .ob-lg-76p { bottom: 76%; }
  .ob-lg-77p { bottom: 77%; }
  .ob-lg-78p { bottom: 78%; }
  .ob-lg-79p { bottom: 79%; }
  .ob-lg-80p { bottom: 80%; }
  .ob-lg-81p { bottom: 81%; }
  .ob-lg-82p { bottom: 82%; }
  .ob-lg-83p { bottom: 83%; }
  .ob-lg-84p { bottom: 84%; }
  .ob-lg-85p { bottom: 85%; }
  .ob-lg-86p { bottom: 86%; }
  .ob-lg-87p { bottom: 87%; }
  .ob-lg-88p { bottom: 88%; }
  .ob-lg-89p { bottom: 89%; }
  .ob-lg-90p { bottom: 90%; }
  .ob-lg-91p { bottom: 91%; }
  .ob-lg-92p { bottom: 92%; }
  .ob-lg-93p { bottom: 93%; }
  .ob-lg-94p { bottom: 94%; }
  .ob-lg-95p { bottom: 95%; }
  .ob-lg-96p { bottom: 96%; }
  .ob-lg-97p { bottom: 97%; }
  .ob-lg-98p { bottom: 98%; }
  .ob-lg-99p { bottom: 99%; }
  .ob-lg-100p { bottom: 100%; }

  .ow-lg-auto { width: auto; }
  .ow-lg-1p { width: 1%; }
  .ow-lg-2p { width: 2%; }
  .ow-lg-3p { width: 3%; }
  .ow-lg-4p { width: 4%; }
  .ow-lg-5p { width: 5%; }
  .ow-lg-6p { width: 6%; }
  .ow-lg-7p { width: 7%; }
  .ow-lg-8p { width: 8%; }
  .ow-lg-9p { width: 9%; }
  .ow-lg-10p { width: 10%; }
  .ow-lg-11p { width: 11%; }
  .ow-lg-12p { width: 12%; }
  .ow-lg-13p { width: 13%; }
  .ow-lg-14p { width: 14%; }
  .ow-lg-15p { width: 15%; }
  .ow-lg-16p { width: 16%; }
  .ow-lg-17p { width: 17%; }
  .ow-lg-18p { width: 18%; }
  .ow-lg-19p { width: 19%; }
  .ow-lg-20p { width: 20%; }
  .ow-lg-21p { width: 21%; }
  .ow-lg-22p { width: 22%; }
  .ow-lg-23p { width: 23%; }
  .ow-lg-24p { width: 24%; }
  .ow-lg-25p { width: 25%; }
  .ow-lg-26p { width: 26%; }
  .ow-lg-27p { width: 27%; }
  .ow-lg-28p { width: 28%; }
  .ow-lg-29p { width: 29%; }
  .ow-lg-30p { width: 30%; }
  .ow-lg-31p { width: 31%; }
  .ow-lg-32p { width: 32%; }
  .ow-lg-33p { width: 33%; }
  .ow-lg-34p { width: 34%; }
  .ow-lg-35p { width: 35%; }
  .ow-lg-36p { width: 36%; }
  .ow-lg-37p { width: 37%; }
  .ow-lg-38p { width: 38%; }
  .ow-lg-39p { width: 39%; }
  .ow-lg-40p { width: 40%; }
  .ow-lg-41p { width: 41%; }
  .ow-lg-42p { width: 42%; }
  .ow-lg-43p { width: 43%; }
  .ow-lg-44p { width: 44%; }
  .ow-lg-45p { width: 45%; }
  .ow-lg-46p { width: 46%; }
  .ow-lg-47p { width: 47%; }
  .ow-lg-48p { width: 48%; }
  .ow-lg-49p { width: 49%; }
  .ow-lg-50p { width: 50%; }
  .ow-lg-51p { width: 51%; }
  .ow-lg-52p { width: 52%; }
  .ow-lg-53p { width: 53%; }
  .ow-lg-54p { width: 54%; }
  .ow-lg-55p { width: 55%; }
  .ow-lg-56p { width: 56%; }
  .ow-lg-57p { width: 57%; }
  .ow-lg-58p { width: 58%; }
  .ow-lg-59p { width: 59%; }
  .ow-lg-60p { width: 60%; }
  .ow-lg-61p { width: 61%; }
  .ow-lg-62p { width: 62%; }
  .ow-lg-63p { width: 63%; }
  .ow-lg-64p { width: 64%; }
  .ow-lg-65p { width: 65%; }
  .ow-lg-66p { width: 66%; }
  .ow-lg-67p { width: 67%; }
  .ow-lg-68p { width: 68%; }
  .ow-lg-69p { width: 69%; }
  .ow-lg-70p { width: 70%; }
  .ow-lg-71p { width: 71%; }
  .ow-lg-72p { width: 72%; }
  .ow-lg-73p { width: 73%; }
  .ow-lg-74p { width: 74%; }
  .ow-lg-75p { width: 75%; }
  .ow-lg-76p { width: 76%; }
  .ow-lg-77p { width: 77%; }
  .ow-lg-78p { width: 78%; }
  .ow-lg-79p { width: 79%; }
  .ow-lg-80p { width: 80%; }
  .ow-lg-81p { width: 81%; }
  .ow-lg-82p { width: 82%; }
  .ow-lg-83p { width: 83%; }
  .ow-lg-84p { width: 84%; }
  .ow-lg-85p { width: 85%; }
  .ow-lg-86p { width: 86%; }
  .ow-lg-87p { width: 87%; }
  .ow-lg-88p { width: 88%; }
  .ow-lg-89p { width: 89%; }
  .ow-lg-90p { width: 90%; }
  .ow-lg-91p { width: 91%; }
  .ow-lg-92p { width: 92%; }
  .ow-lg-93p { width: 93%; }
  .ow-lg-94p { width: 94%; }
  .ow-lg-95p { width: 95%; }
  .ow-lg-96p { width: 96%; }
  .ow-lg-97p { width: 97%; }
  .ow-lg-98p { width: 98%; }
  .ow-lg-99p { width: 99%; }
  .ow-lg-100p { width: 100%; }
}
