.button-on-image {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 290px !important;
  height: 290px !important;
  top: calc(50% - 145px);
  left: calc(50% - 145px);

  //border: 1px solid black;
  border-radius: 50%;
  background-color: $color-ginger-gold;

  a {
    text-decoration: none !important;
    border-radius: 50%;
    border: 0.125rem solid white;
    background-color: $color-ginger-gold;

    width: 100%;
    height: 100% !important;
  }

  h2 {
    color: white;
    font-size: 3rem;
    font-weight: 400;
    font-family: 'Quicksand', serif;
    margin-bottom: 0;
    text-transform: uppercase;
    text-align: center;
  }

  p {
    color: black;
    font-size: 1.5rem;
    line-height: 1.2rem;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
  }
}
