//Sectie buttons van categorieen en zoekbalk

.categories-type-3 {
  h2 {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 800;
    font-size: 1.875rem;
  }
  .swiper {
    //@media only screen and (max-width: 992px) {
    //  margin-right: -14px;
    //  margin-left: -14px;
    //}

    padding: 12px 0;

    .swiper-slide {
      display: inline-block;
      height: 92px;
      a {
        text-transform: uppercase;
        text-decoration: none;
        color: black;
        text-align: center;
        display: inline-block;

        .button-images-round {
          width: 72px;
          height: 72px;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            //border: 2px solid #c3a425;
            border-radius: 50%;
          }
        }

        span {
          font-weight: 400;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 0.75rem;
          margin-bottom: 0;
          font-family: 'Josefin Sans', sans-serif;

          @media (min-width: 992px) {
            text-align: center;
            font-size: 0.813rem;
          }
        }
      }
    }
  }
}
