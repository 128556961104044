.slider-type-3 {
  h2 {
    color: var(--cat2-title-text);
    font-size: 1.25rem;
    font-family: 'Josefin Regular', serif;
    margin: 1rem 0;
  }

  .swiper{
    .swiper-slide {
      text-align: center;
      position: relative;
      //overflow: hidden;
      //margin-top: 1rem;
      //height: 400px;

      a {
        display: block;
        text-decoration: none;
        height: 100%;

        .slider-image {
          height: 85%;
        }

        h3 {
          display: block;
          position: relative;
          color: var(--cat2-subtitle-text);
          font-family: 'Josefin Regular', serif;
          font-size: 1rem;
          text-align: left;
          margin-top: 0.5rem;
        }
      }
    }
  }
}
