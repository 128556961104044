////Sectie new on the blog
.content-block {
  position: relative;
  //min-height: 100vw;
  width: 100%;
  overflow: hidden;

  .container {
    aspect-ratio: 1200 / 460;
  }

  .col {
    overflow: hidden;
  }

  .background-image {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 0;
  }


  &.splitted {
    height: 100%;

    .container {
      aspect-ratio: 600 / 460;
    }

    .background-image {
      position: absolute;
      top: 0;
      left: 0;

      @media only screen and (max-width: 992px) {
        position: relative;
        aspect-ratio: 1 / 1;
      }
    }
  }

  .same-position{
    h2 {
      color: var(--content-title-text);
      font-weight: 300;
      font-size: 2.5rem;
      line-height: 2.6rem;
      font-family: 'Bely Display W00 Regular', serif;
      text-transform: uppercase;
      & span {
        color: var(--content-title-span);
        font-weight: 300;
        text-transform: none;
        font-family: 'Satisfy Regular', serif;
        rotate: -5deg;
        font-size: 4.5rem;
        display: block;
        //margin: -10px 0 10px;
      }
    }

    p {
      color: white;
      font-size: 1.5rem;
      line-height: 1.5rem;
      font-family: "Satisfy Regular", serif;
    }

    span.btn {
      text-decoration: none;
      width: auto;
    }
  }
  .different-position{
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 5;

    h2 {
      color: var(--content-title-text);
      font-weight: 300;
      font-size: 2.5rem;
      line-height: 2.6rem;
      font-family: 'Playfair Display', serif;
      text-transform: uppercase;
      & span {
        color: #eeaeae;
        text-transform: none;
        font-weight: 900;
        font-family: 'Playfair Display', serif;
      }
    }

    p {
      color: white;
      font-size: 1.5rem;
      line-height: 1.5rem;
      font-family: "Satisfy Regular", serif;
    }

    a {
      text-decoration: none;
      font-style: italic;
      color: #eeaeae;
      font-weight: 900;
      font-family: 'Playfair Display', serif;

      span.btn {
        text-decoration: none;
        color: var(--blog-btn-text);
        width: auto;
        border-radius: 0.938rem;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    aspect-ratio: 1 / 1;
  }
}
