////Sectie voor klantenfoto's
.customer-photo {
  text-align: center;
  background-color: #FBE5E75A;

  @media screen and (min-width: 1200px) {
    width: 100vw;
    margin-left: calc(600px - 50vw);
  }

  h2 {
    color: var(--cust-title-text);
    text-align: left;
    font-weight: bold;
    margin-bottom: 0;
    font-family: 'Josefin Sans', serif;
    font-size: 1.875rem;
    text-transform: uppercase;
    @media screen and (max-width: 992px) {
      text-align: left !important;
      padding-left: 0.75rem;
      font-size: 1.375rem;
    }
  }

  p {
    color: var(--cust-subtitle-text);
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
    font-size: 1rem;
    line-height: $font-size-base;
    text-align: left;
    font-family: $font-family-body;
    @media screen and (max-width: 992px){
      text-align: left !important;
      padding: 0 0.75rem 0 0.75rem;
    }
  }
  a.btn{
    width: 90%;
    background-color: black;
    color: white;
    border-radius: 0.938rem;

    &:focus, &:active {
      background-color: black;
      color:white;
    }
    @media only screen and (min-width: 768px) {
      width: 25%;
    }
  }

  .swiper-slide {
    margin-top: 1rem;
    aspect-ratio: 1/1.2;

    //height: 200px;
    //width: 200px;

    //@media only screen and (min-width: 440px) {
    //  height: 250px;
    //  width: 250px;
    //}
    //
    //@media only screen and (min-width: 475px) {
    //  height: 275px;
    //  width: 275px;
    //}
    //
    //@media only screen and (min-width: 500px) {
    //  height: 300px;
    //  width: 300px;
    //}
    //
    //@media only screen and (min-width: 525px) {
    //  height: 325px;
    //  width: 325px;
    //}
    //
    //@media only screen and (min-width: 550px) {
    //  height: 350px;
    //  width: 350px;
    //}

    img {
      height: 100%;
      width: 100%;
      object-position: top center;
    }

    a {
      text-decoration: none;
      background-color: white;
      position: absolute;
      bottom: 3.75rem;
      right: 0.938rem;
      z-index: 2;
      border-radius: 50% !important;

      @media only screen and (max-width: 992px) {
        bottom: 2.75rem;
        right: 0.938rem;
      }

      &.btn {
        width: 40px;
        height: 40px !important;
        border-radius: 0.938rem;

        &:focus, &:active {
          background-color: white;
        }

        img {
          width: 23px;
          height: 23px;
        }
      }
    }

    p {
      font-family: $font-family-body;
      font-size: $font-size-small;
      @media screen and (max-width: 992px){
        display: none;
      }
    }
  }
}
