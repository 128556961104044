.banner-block {
  min-height: 100px;
  padding-bottom: 15px;
  h2 {
    color: black;
    font-weight: 600;
    font-size: 1.25rem;
    font-family: $font-family-header;
    text-transform: uppercase;
  }

  .banner-block-inner{
    padding: 20px 0;
    .items{
      max-width: 516px;
      margin: 0 auto;
      @media screen and (max-width: 576px){
        flex-wrap: wrap;
        row-gap: 15px;
      }
      a.btn-link {
        text-decoration: none;
        color: black;
        font-weight: 400;
        font-size: $font-size-base;
        font-family: $font-family-body;
        text-transform: none !important;
        @media screen and (max-width: 576px) {
          flex: 1 0 33.3%

        }
      }
    }

    // This is a color setting coming from Pulse
    &.bg-season-3 {
      background-color: $color-cotton-candy;
    }
  }
}

.banner-block {
  min-height: 100px;
}
